.App {
  text-align: center;
}

.App-logo {
  height: 225px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.links {
  width: 100%;
}

.link-logo {
  height: 35px;
}

.instagram-logo {
  margin-right: 20px;
}
